const customIcons = {
  values: {
    search: {
      type: "icon",
      icon: "fa-search",
    },
    howToCite: {
      type: "icon",
      icon: "fa-link",
    },
    PublicationCitation: {
      type: "icon",
      icon: "fa-book-open",
    },
    createdAt: {
      type: "icon",
      icon: "fa-folder-plus",
    },
    updatedAt: {
      type: "icon",
      icon: "fa-sync",
    },
    tools: {
      type: "icon",
      icon: "fa-tools",
    },
    contacts: {
      type: "icon",
      icon: "fa-envelope",
    },
    maintains: {
      type: "icon",
      icon: "fa-sitemap",
    },
    funds: {
      type: "img",
      icon: "/assets/icons/fund-icon.png",
    },
    collaborates_on: {
      type: "icon",
      icon: "fa-handshake",
    },
    other_involvement: {
      type: "icon",
      icon: "fa-building",
    },
    undefined: {
      type: "icon",
      icon: "fa-question",
    },
    arrowUp: {
      type: "icon",
      icon: "fa-chevron-up",
    },
    data_access: {
      type: "icon",
      icon: "fa-key",
    },
    data_curation: {
      type: "icon",
      icon: "fa-database",
    },
    data_release: {
      type: "icon",
      icon: "fa-recycle",
    },
    data_versioning: {
      type: "icon",
      icon: "fa-sync",
    },
    nodes: {
      type: "img",
      icon: "/assets/icons/nodes.png",
    },
    licences: {
      type: "icon",
      icon: "fa-certificate",
    },
    certificate: {
      type: "img",
      icon: "/assets/icons/certificate-badge.svg",
    },
    frequently_asked_questions_faqs: {
      type: "icon",
      icon: "fa-question-circle",
    },
    forum: {
      type: "icon",
      icon: "fa-comment",
    },
    help_documentation: {
      type: "icon",
      icon: "fa-book",
    },
    blog_news: {
      type: "icon",
      icon: "fa-newspaper",
    },
    twitter: {
      type: "icon",
      icon: "fab fa-twitter",
    },
    wikipedia: {
      type: "icon",
      icon: "fab fa-wikipedia-w",
    },
    github: {
      type: "icon",
      icon: "fab fa-github",
    },
    facebook: {
      type: "icon",
      icon: "fab fa-facebook",
    },
    training_documentation: {
      type: "icon",
      icon: "fa-funnel-dollar",
    },
    tess_links_to_training_materials: {
      type: "img",
      icon: "/assets/icons/elixir-tess.png",
    },
    support_email: {
      type: "icon",
      icon: "fa-headset",
    },
    collection: {
      type: "img",
      icon: "/assets/records/collection-icon.svg",
      tooltip: "Collection",
    },
    journal: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Journal",
    },
    identifier_schema: {
      type: "img",
      icon: "/assets/records/identifier_schema.svg",
      tooltip: "Identifier Schema",
    },
    society: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Society",
    },
    funder: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Funder",
    },
    project: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Project",
    },
    institution: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Institution",
    },
    terminology_artefact: {
      type: "img",
      icon: "/assets/records/terminology_artefact.svg",
      tooltip: "Terminology Artefact",
    },
    model_and_format: {
      type: "img",
      icon: "/assets/records/model_and_format.svg",
      tooltip: "Model and Format",
    },
    reporting_guideline: {
      type: "img",
      icon: "/assets/records/reporting_guideline.svg",
      tooltip: "Reporting Guideline",
    },
    metric: {
      type: "img",
      icon: "/assets/records/metric.svg",
      tooltip: "Metric",
    },
    repository: {
      type: "img",
      icon: "/assets/records/db-icon.svg",
      tooltip: "Repository",
    },
    journal_publisher: {
      type: "img",
      icon: "/assets/records/policy.svg",
      tooltip: "Journal Publisher",
    },
    knowledgebase: {
      type: "img",
      icon: "/assets/records/db-icon.svg",
      tooltip: "Knowledge Base",
    },
    knowledgebase_and_repository: {
      type: "img",
      icon: "/assets/records/db-icon.svg",
      tooltip: "Knowledge Base & repository",
    },
    benchmark: {
      type: "img",
      icon: "/assets/records/benchmark.svg",
      tooltip: "Benchmark",
    },
    DOI: {
      type: "img",
      icon: "/assets/icons/DOI_logo.svg",
    },
    Orcid: {
      type: "img",
      icon: "/assets/icons/ORCID_iD.svg",
    },
    mailing_list: {
      type: "icon",
      icon: "fa-address-book",
    },
    contact_form: {
      type: "icon",
      icon: "fa-address-card",
    },
    recommended: {
      type: "icon",
      icon: "fa-thumbs-up",
    },
    video: {
      type: "icon",
      icon: "fa-video",
    },
    collections: {
      type: "img",
      icon: "/assets/Home/BlockInfo/collection-icon.svg",
    },
    educational: {
      type: "icon",
      icon: "fa-graduation-cap",
    },
    api: {
      type: "icon",
      icon: "fa-laptop-code",
    },
    home_standard: {
      type: "img",
      icon: "/assets/Home/BlockInfo/home-standards.svg",
    },
    home_db: {
      type: "img",
      icon: "/assets/Home/BlockInfo/home-db.svg",
    },
    home_policies: {
      type: "img",
      icon: "/assets/Home/BlockInfo/home-policies.svg",
    },
    other: {
      type: "icon",
      icon: "fa-ellipsis-h",
    },
    adopters: {
      type: "icon",
      icon: "fa-file-alt",
    },
    activities: {
      type: "icon",
      icon: "fa-chart-line",
    },
    governance: {
      type: "icon",
      icon: "fa-university",
    },
    linkedin: {
      type: "icon",
      icon: "fab fa-linkedin",
    },
    home: {
      type: "icon",
      icon: "fa-home",
    },
    user: {
      type: "icon",
      icon: "fa-user",
    },
    users: {
      type: "icon",
      icon: "fa-users",
    },
    collaborators: {
      type: "icon",
      icon: "fa-users-cog",
    },
    info: {
      type: "icon",
      icon: "fa-info",
    },
  },
  iconfont: "fa",
};

export default customIcons;
